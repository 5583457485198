'use client';
import React, { useEffect, useState } from 'react';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import FAQs from '@/app/_components/faqs/FAQs';
import PageContent from '@/app/_components/page-content/PageContent';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { CoverageComponent } from '@/app/_types/components/coverage';
import { ManagerRepeater } from '@/app/_types/components/partner';
import { FilesMeta } from '@/app/_types/file';
import { ProductLandingVariation3 } from '@/app/_types/product-landing';

import ContactForm from '../ContactForm';
import CtaBanner from '../CtaBanner';
import GetInContact from '../GetInContact';
import IconCardSection from '../IconCardSection';

import OfferingSection from './OfferingSection';
import PartnershipTabs from './PartnershipTabs';

type ProductLandingVariation3Props = ProductLandingVariation3 & {
  breadcrumbs?: Breadcrumb[] | null;
  filesMeta: FilesMeta;
};
const ProductVariation3 = (props: ProductLandingVariation3Props) => {
  const {
    about_us,
    faq_pre_text,
    faq_title,
    faqs,
    form,
    header,
    offering_paragraph,
    offering_title,
    offering_pre_text,
    offerings,
    partners,
    filesMeta,
    breadcrumbs,
    partner_title,
    partner_pre_text,
  } = props;

  const [partnerCoverage, setPartnerCoverage] =
    useState<CoverageComponent | null>(null);

  const [managerList, setManagerList] = useState<ManagerRepeater[] | null>(
    null,
  );

  const [managerHeader, setManagerHeader] = useState({
    title: '',
    preText: '',
  });

  const handlePartnerCoverage = (coverage: CoverageComponent | null) => {
    setPartnerCoverage(coverage);
  };

  const handleManagers = (managers: ManagerRepeater[] | null) => {
    setManagerList(managers);
  };

  const handleManagerHeader = (title: string, preText: string) => {
    setManagerHeader({ title, preText });
  };

  useEffect(() => {
    if (partners) {
      const { coverage, managers, manager_title, manager_pre_text } =
        partners[0].partners_component_id;

      if (coverage) {
        setPartnerCoverage(coverage);
      }
      if (managers) {
        setManagerList(managers);
        setManagerHeader({
          title: manager_title || 'Contact details',
          preText: manager_pre_text || 'Get in touch',
        });
      }
    }
  }, []);

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {about_us &&
        about_us?.length > 0 &&
        about_us?.map(({ copy_component_id }) => (
          <PageContent
            key={copy_component_id?.id}
            {...copy_component_id}
            smallContent={true}
          />
        ))}
      {offerings && offerings?.length > 0 && (
        <OfferingSection
          offerings={offerings}
          title={offering_title}
          preText={offering_pre_text}
          paragraph={offering_paragraph}
          filesMeta={filesMeta}
        />
      )}
      {partners && partners.length > 0 && (
        <PartnershipTabs
          handlePartnerCoverage={handlePartnerCoverage}
          handleManagers={handleManagers}
          partners={partners}
          title={partner_title}
          preText={partner_pre_text}
          filesMeta={filesMeta}
          handleManagerHeader={handleManagerHeader}
        />
      )}
      {partnerCoverage?.coverage && partnerCoverage?.coverage?.length > 0 && (
        <IconCardSection {...partnerCoverage} filesMeta={filesMeta} />
      )}
      {managerList && managerList?.length > 0 && (
        <GetInContact
          managerList={managerList}
          preText={managerHeader.preText}
          title={managerHeader.title}
        />
      )}
      {form && <ContactForm {...form} filesMeta={filesMeta} />}
      {faqs && faqs?.length > 0 && (
        <FAQs
          faqsList={faqs}
          preText={faq_pre_text ?? ''}
          faqTitle={faq_title ?? ''}
        />
      )}
    </>
  );
};

export default ProductVariation3;
