'use client';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { useWindowSize } from '@/app/_hooks/window-size.hook';
import { CardM2M } from '@/app/_types/components/cards';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CategoryCard.module.scss';
type CategoryCardProps = CardM2M & {
  filesMeta: FilesMeta;
};
const CategoryCard = (props: CategoryCardProps) => {
  const { card_id, filesMeta } = props;
  const { width } = useWindowSize();

  return (
    <div className={Styles.categoryCard}>
      <div className={Styles.categoryHeading}>
        {card_id.title && (
          <div className="headingElement">
            <h2 className="titleXl medium">{card_id.title}</h2>
          </div>
        )}
      </div>
      <div className={Styles.categoryContent}>
        <div className={Styles.categoryContentContainer}>
          <div>
            {card_id.inner_title && (
              <div className="headingElement">
                <h3 className="titleMd light mb-4">{card_id.inner_title}</h3>
              </div>
            )}

            {card_id.paragraph && (
              <div className={Styles.paragraph}>{card_id.paragraph}</div>
            )}
            {card_id.view_offerings_text && width && width > 992 && (
              <div className="mt-6">
                <ButtonCustom
                  btnText={card_id.view_offerings_text}
                  href={card_id.category?.navigation?.permalink || ''}
                  className="btnPrimaryOutline"
                />
              </div>
            )}
          </div>
          {card_id.quick_links && (
            <ul className={Styles.productLists}>
              {card_id.quick_links.map((product, index) => (
                <li key={index}>
                  <Link
                    href={product.url || ''}
                    className={Styles.productListLink}
                    target={product.new_tab === true ? '_blank' : '_self'}
                  >
                    {product.text}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={Styles.categoryImageContainer}>
          {card_id.image && (
            <Image
              src={getDirectusAssetURI(card_id.image ?? '') || ''}
              alt={getAltProperty(filesMeta, card_id.image ?? '', 'Banner')}
              className={Styles.categoryImage}
              width={620}
              height={350}
            />
          )}
        </div>
        {card_id.view_offerings_text && width && width <= 992 && (
          <div>
            <ButtonCustom
              btnText={card_id.view_offerings_text}
              href={card_id.category?.navigation?.permalink || ''}
              className="btnPrimaryOutline"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
