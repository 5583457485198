'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useRef } from 'react';
import SwiperCore from 'swiper'; // Import Autoplay module
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import arrowNext from '@/app/_icons/arrow-next.png';
import arrowPrev from '@/app/_icons/arrow-prev.png';
import { CampaignSliderType } from '@/app/_types/components/campaign-slider';
import { FilesMeta } from '@/app/_types/file';
import {
  getAltProperty,
  getDirectusAssetURI,
  getDirectusDownloadAssetURI,
} from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CampaignSlider.module.scss';

import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation, Autoplay]);

type CampaignSliderProps = {
  slides: CampaignSliderType[];
  filesMeta: FilesMeta;
  fullWidth?: boolean;
};

const CampaignSlider = (props: CampaignSliderProps) => {
  const { slides, filesMeta, fullWidth = true } = props;
  const swiperRef = useRef<SwiperCore | null>(null);

  const handleSwipeNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handleSwipePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const showNavigationButtons = slides.length > 1;

  return (
    <div className="pageSection">
      <div className={Styles.campaignSliderWrapper}>
        <div
          className={cn(
            fullWidth ? Styles.fullWidthContainer : Styles.smallContainer,
          )}
        >
          <div className={Styles.helpCardContainer}>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              spaceBetween={40}
              slidesPerView={1}
              loop={slides.length > 1}
              autoplay={slides.length > 1 ? { delay: 5000 } : false}
              className="mySwiper"
              speed={500}
            >
              {slides &&
                slides.map((item, index) => {
                  const { call_to_action } = item.cta_banner_id;
                  const ctaUrl = call_to_action?.pdf
                    ? getDirectusDownloadAssetURI(call_to_action?.pdf)
                    : call_to_action?.url;
                  const target = call_to_action?.pdf ? '_blank' : '_self';

                  return (
                    <SwiperSlide key={index}>
                      <div className={Styles.helpCardContent}>
                        <div className={Styles.leftContent}>
                          {(item?.cta_banner_id?.title ||
                            item?.cta_banner_id?.title_2) && (
                            <div
                              className={cn('headingElement', Styles.heading)}
                            >
                              {item.cta_banner_id.pre_text && (
                                <h4 className={cn('subTitle', Styles.preText)}>
                                  {item.cta_banner_id.pre_text}
                                </h4>
                              )}
                              <h2 className="titleXl strong">
                                {item.cta_banner_id.title && (
                                  <span className="normal">
                                    {item.cta_banner_id.title}
                                  </span>
                                )}

                                {item.cta_banner_id?.title_2 &&
                                  item.cta_banner_id?.title_2}
                              </h2>
                            </div>
                          )}

                          {item.cta_banner_id.paragraph && (
                            <div
                              className={cn(
                                Styles.description,
                                'editorDescription',
                              )}
                              dangerouslySetInnerHTML={{
                                __html: item.cta_banner_id.paragraph,
                              }}
                            ></div>
                          )}
                          {item.cta_banner_id.contact_number && (
                            <div className={Styles.contactBlock}>
                              <div className={Styles.divider} />
                              <h2>{item.cta_banner_id.contact_number || ''}</h2>
                              <p>
                                {item.cta_banner_id.operating_hours ||
                                  'Open 24/7, including public holidays'}
                              </p>
                            </div>
                          )}

                          {item.cta_banner_id.call_to_action && ctaUrl && (
                            <ButtonCustom
                              btnText={item.cta_banner_id.call_to_action?.text}
                              className="btnPrimaryAlt"
                              extraClass={Styles.ctaCardBtn}
                              href={ctaUrl || ''}
                              target={target}
                            />
                          )}
                        </div>
                        <div className={Styles.rightContent}>
                          {item.cta_banner_id.image && (
                            <Image
                              src={
                                getDirectusAssetURI(item.cta_banner_id.image) ??
                                ''
                              }
                              alt={getAltProperty(
                                filesMeta,
                                item.cta_banner_id.image ?? '',
                                'Banner',
                              )}
                              width={700}
                              height={380}
                              className={Styles.cardImage}
                              quality={90}
                            />
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          {showNavigationButtons && (
            <div className={Styles.swipeWrapper}>
              <button
                className={Styles.swiperIconBtn}
                onClick={handleSwipePrevClick}
              >
                <Image src={arrowPrev} alt="Previous" height={30} width={30} />
                Previous
              </button>
              <button
                className={Styles.swiperIconBtn}
                onClick={handleSwipeNextClick}
              >
                Next
                <Image src={arrowNext} alt="Next" height={30} width={30} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignSlider;
