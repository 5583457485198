'use client';

import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import CloudflareTurnstile from '@/app/_components/cloudflare-turnstile/CloudflareTurnstile';
import FormInput from '@/app/_components/form/FormInput';
import ThankYou from '@/app/_components/thankyou/ThankYou';
import { Form as FormType } from '@/app/_types/components/form';
import { FilesMeta } from '@/app/_types/file';
import { saveFormData } from '@/app/actions';

import Styles from './ContactForm.module.scss';

type Props = FormType & {
  filesMeta: FilesMeta;
};

const ContactForm = (props: Props) => {
  const [isTurnstileLoading, setIsTurnstileLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);

  const pathname = usePathname();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isFormValid, setIsFormValid] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const saveData = saveFormData.bind(
    null,
    pathname,
    props.submission_key,
    props.id,
  );
  const [success, setSuccess] = useState(false);
  const contactFormRef = useRef<HTMLDivElement | null>(null);
  const offset = 120;

  const getTurnstileToken = (token: string) => {
    setIsTurnstileLoading(false);
    setTurnstileToken(token);
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const handleSubmit = async (data: any) => {
    if (!turnstileToken && process.env.NEXT_PUBLIC_TURNSTILE_SITEKEY) {
      messageApi.open({
        type: 'error',
        content: 'Please complete the verification.',
        duration: 5,
      });
      return;
    }

    const response = await saveData({ ...data, turnstileToken });

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      setSuccess(false);
    } else {
      form.resetFields();
      if (contactFormRef.current) {
        const scrollPosition = contactFormRef.current.offsetTop - offset;

        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
      setSuccess(true);
      setTurnstileToken(null);
    }
  };

  const thankYouData = {
    submittedTitle: props.submitted_title,
    submittedParagraph: props.submitted_paragraph,
    submittedImage: props.submitted_image,
  };

  return (
    <div className={Styles.contactFormWrapper} ref={contactFormRef}>
      <div className="headingElement">
        <h3 className="subTitle">{props.pre_text}</h3>
        <h2 className="titleXl">{props.title}</h2>
      </div>
      {contextHolder}
      {success ? (
        <ThankYou {...thankYouData} filesMeta={props.filesMeta} />
      ) : (
        <Form
          form={form}
          className={Styles.contactForm}
          size="large"
          onFinish={handleSubmit}
        >
          <div className={Styles.contactFormField}>
            {props.form_inputs.map((input) => (
              <FormInput key={input.id} input={input} form={form} />
            ))}
          </div>
          <CloudflareTurnstile
            onSuccess={getTurnstileToken}
            onError={() => setIsTurnstileLoading(false)}
            onLoad={() => setIsTurnstileLoading(true)}
          />
          <div>
            <ButtonCustom
              htmlType="submit"
              disabled={
                !form.isFieldsTouched() || !isFormValid || isTurnstileLoading
              }
            >
              {props.submit_text}
            </ButtonCustom>
          </div>
        </Form>
      )}
    </div>
  );
};

export default ContactForm;
