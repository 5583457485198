'use client';

import cn from 'classnames';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React, { useEffect, useRef } from 'react';

import { headerAboutUsAnchor } from '@/app/_constants/header.constants';
import { assetsUri } from '@/app/_services/api.service';
import { AboutUsVariation1 } from '@/app/_types/about-us';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type Props = AboutUsVariation1['header'] & {
  filesMeta: FilesMeta;
};
const CtaBanner = (props: Props) => {
  const { title, title_2, paragraph, image_1, image_2, image_3, filesMeta } =
    props;

  const pathName = usePathname();
  const headerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 120;
    const hash = window.location.hash;
    const activeSection = hash.replace('#', '');

    if (activeSection === headerAboutUsAnchor?.whoWeAre && headerRef.current) {
      const scrollPosition = headerRef.current.offsetTop - offset;

      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  }, [pathName]);

  return (
    <div
      className={cn('container', Styles.ctaBannerContainer)}
      id={headerAboutUsAnchor?.whoWeAre}
      ref={headerRef}
    >
      <div className={Styles.ctaBannerWrapper}>
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {title && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                <span className="light">{title}</span>
                {title_2 && title_2}
              </h1>
            )}
          </div>
          {paragraph && (
            <div
              className={cn('normal', Styles.description)}
              dangerouslySetInnerHTML={{
                __html: paragraph,
              }}
            ></div>
          )}
        </div>
        <div className={Styles.bannerImageContainer}>
          <div className={Styles.bannerImageWrapper}>
            <div className={Styles.bannerBigImageContainer}>
              <Image
                src={`${assetsUri}/${image_1}`}
                alt={getAltProperty(filesMeta, image_1 ?? '', 'Banner')}
                width={784}
                height={734}
                className={Styles.bannerImage}
                quality={90}
                priority
              />
            </div>
            <div className={Styles.bannerSmallImageContainer}>
              <Image
                src={`${assetsUri}/${image_2}`}
                alt={getAltProperty(filesMeta, image_2 ?? '', 'Banner')}
                width={574}
                height={482}
                className={Styles.bannerImage}
                quality={90}
              />
              <Image
                src={`${assetsUri}/${image_3}`}
                alt={getAltProperty(filesMeta, image_3 ?? '', 'Banner')}
                width={574}
                height={482}
                className={Styles.bannerImage}
                quality={90}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
