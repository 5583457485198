'use client';
import React from 'react';

import BlogArticleCards from '@/app/_components/blog-article-cards/BlogArticleCards';
import FAQs from '@/app/_components/faqs/FAQs';
import PageContent from '@/app/_components/page-content/PageContent';
import { Blog } from '@/app/_types/blog';
import { DocumentGroupM2M } from '@/app/_types/components/document-group';
import { FilesMeta } from '@/app/_types/file';
import { ResourcesVariation2 } from '@/app/_types/resources-page';

import CtaBanner from './CtaBanner';
import FormsAndDocuments from './FormsAndDocuments';
import GuidelinesSection from './GuidelinesSection';

type Props = ResourcesVariation2 & {
  filesMeta: FilesMeta;
  blogsList: Blog[];
  formAndDocumentList: DocumentGroupM2M[];
};

const ResourcesPageVariation2 = (props: Props) => {
  const {
    header,
    formAndDocumentList,
    documents_title,
    documents_pre_text,
    document_cta_text,
    document_cta_url,
    insurance_claims_pre_text,
    insurance_claims_title,
    claims_and_assesments,
    faq_title,
    faq_pre_text,
    faqs,
    blog_title,
    blog_pre_text,
    blog_cta_text,
    blog_cta_url,
    blog_detail_navigation,
    filesMeta,
    blogsList,
    copy_component,
    content_copy,
  } = props;

  const blogDetailPermalink = blog_detail_navigation?.permalink;
  let blogBaseUrl = '';

  if (blogDetailPermalink) {
    blogBaseUrl = blogDetailPermalink.replaceAll('*', '');
  }

  return (
    <>
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {formAndDocumentList && formAndDocumentList?.length > 0 && (
        <FormsAndDocuments
          documentList={formAndDocumentList}
          documentTitle={documents_title}
          preText={documents_pre_text}
          ctaText={document_cta_text}
          ctaUrl={document_cta_url}
        />
      )}

      {content_copy &&
        content_copy.length > 0 &&
        content_copy.map((item, i) => {
          const { copy_component_id } = item;

          return <PageContent key={i} {...copy_component_id} />;
        })}
      {claims_and_assesments && claims_and_assesments?.length > 0 && (
        <GuidelinesSection
          claimsList={claims_and_assesments}
          preText={insurance_claims_pre_text}
          title={insurance_claims_title}
          filesMeta={filesMeta}
        />
      )}
      {copy_component && <PageContent {...copy_component} />}
      {blogsList && blogsList?.length > 0 && (
        <BlogArticleCards
          blogList={blogsList}
          title={blog_title}
          preText={blog_pre_text}
          ctaText={blog_cta_text}
          ctaUrl={blog_cta_url}
          blogBaseUrl={blogBaseUrl}
          filesMeta={filesMeta}
        />
      )}

      {faqs && faqs?.length > 0 && (
        <FAQs faqsList={faqs} preText={faq_pre_text} faqTitle={faq_title} />
      )}
    </>
  );
};

export default ResourcesPageVariation2;
